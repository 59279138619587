import _padLeft from "pad-left";
var exports = {};
var padLeft = _padLeft;
exports = addLineNumbers;

function addLineNumbers(string, start, delim) {
  start = typeof start === "number" ? start : 1;
  delim = delim || ": ";
  var lines = string.split(/\r?\n/);
  var totalDigits = String(lines.length + start - 1).length;
  return lines.map(function (line, i) {
    var c = i + start;
    var digits = String(c).length;
    var prefix = padLeft(c, totalDigits - digits);
    return prefix + delim + line;
  }).join("\n");
}

export default exports;